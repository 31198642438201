<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent overlay-opacity="0.8">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" class="headline" style="color: black">
              {{ $t("favoritesPage.deleteDialogTitle") }}
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text
          class="body-1"
          style="font-size: 1.3rem; color: black"
        >
          {{ $t("favoritesPage.deleteDialogBody") }}
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              elevation="0"
              block
              @click="$emit('close')"
              color="grey lighten-3"
              class="text-capitalize mb-4"
              style="font-size: 1.1rem; letter-spacing: 0px"
            >
              {{ $t("favoritesPage.cancel") }}
            </v-btn>

            <v-btn
              elevation="0"
              block
              @click="deleteCollection"
              :loading="loading"
              color="red"
              dark
              class="text-capitalize"
              style="font-size: 1.1rem; letter-spacing: 0px"
            >
              {{ $t("favoritesPage.delete") }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "collection"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteCollection() {
      this.loading = true;
      this.$store
        .dispatch("favoritesModule/deleteCollection", {
          id: this.collection.id,
        })
        .then(() => {
          this.$emit("done");
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
